<template>
    <Products />
</template>

<script>
import Products from '@apps/Products/index.vue'
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        Products
    }
}
</script>